import React from 'react'
import Helmet from 'react-helmet'
import { TitleWrapper } from '@components/accounts/Common'
import { styled, Typography } from '@mui/material'
import OrderList from '@components/order/status/order-list'
import Layout from '../../components/layout'

const StyledLayout = styled(Layout)(({ theme }) => ({
  backgroundColor: `${theme.palette.common.white}!important`,
  '& .content-wrapper': {
    backgroundColor: `${theme.palette.common.white}!important`,
  },
}))

const StyledHeader = styled(Typography)(({ theme }) => ({
  fontSize: '25px',
  fontWeight: 500,
  color: `${theme.palette.primary.dark}`,
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
  },
}))

const OrderDetailsWrapper = () => (
  <StyledLayout>
    <Helmet title="Order Status List - Rooms To Go" />
    <TitleWrapper>
      <StyledHeader>Orders</StyledHeader>
    </TitleWrapper>
    <OrderList />
  </StyledLayout>
)

export default OrderDetailsWrapper
